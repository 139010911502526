<template>
	<div>
		<v-card flat color="secondary" class="pa-3">
			<form v-if="loaded && opened_cash" autocomplete="off" @submit.prevent="submit">
				<v-row dense>
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div
							class="form-group"
							:class="{ 'error--text': errors.operation_type_id }"
						>
							<label>Tipo Operación</label>
							<el-select v-model="form.operation_type_id">
								<el-option
									v-for="option in operation_types"
									:key="option.id"
									:value="option.id"
									:label="option.description"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.operation_type_id"
								v-text="errors.operation_type_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div class="form-group" :class="{ 'error--text': errors.document_type_id }">
							<label>Tipo comprobante</label>
							<el-select
								v-model="form.document_type_id"
								@change="changeDocumentType"
								popper-class="el-select-document_type"
								dusk="document_type_id"
							>
								<el-option
									v-for="option in document_types"
									:key="option.id"
									:value="option.id"
									:label="option.description"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.document_type_id"
								v-text="errors.document_type_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div class="form-group" :class="{ 'error--text': errors.series_id }">
							<label>Serie</label>
							<el-select v-model="form.series_id">
								<el-option
									v-for="option in series"
									:key="option.id"
									:value="option.id"
									:label="option.number"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.series_id"
								v-text="errors.series_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div class="form-group" :class="{ 'error--text': errors.currency_type_id }">
							<label>Moneda</label>
							<el-select v-model="form.currency_type_id" @change="changeCurrencyType">
								<el-option
									v-for="option in currency_types"
									:key="option.id"
									:value="option.id"
									:label="option.description"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.currency_type_id"
								v-text="errors.currency_type_id[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" xl="6" lg="12" sm="12">
						<div class="form-group" :class="{ 'error--text': errors.customer_id }">
							<label
								>Cliente
								<a
									href="#"
									@click.prevent="showDialogNewPerson = true"
									class="white--text"
									>[+ Nuevo]</a
								>
							</label>
							<el-select
								v-model="form.customer_id"
								filterable
								remote
								popper-class="el-select-customers"
								dusk="customer_id"
								placeholder="Buscar..."
								:remote-method="searchRemoteCustomers"
								:loading="loading_search"
								loading-text="Cargando..."
							>
								<el-option
									v-for="option in customers"
									:key="option.id"
									:value="option.id"
									:label="option.description"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.customer_id"
								v-text="errors.customer_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="2" lg="4" md="4" sm="4">
						<div class="form-group" :class="{ 'error--text': errors.date_of_issue }">
							<label>Fec. Emisión</label>
							<el-date-picker
								v-model="form.date_of_issue"
								type="date"
								value-format="yyyy-MM-dd"
								format="dd/MM/yyyy"
								:picker-options="pickerOptions"
								:clearable="false"
								@change="changeDateOfIssue"
								disabled
							></el-date-picker>
							<small
								class="error--text"
								v-if="errors.date_of_issue"
								v-text="errors.date_of_issue[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="2" lg="4" md="4" sm="4">
						<div class="form-group" :class="{ 'error--text': errors.date_of_due }">
							<label>Fec. Vencimiento</label>
							<el-date-picker
								v-model="form.date_of_due"
								type="date"
								value-format="yyyy-MM-dd"
								format="dd/MM/yyyy"
								:picker-options="pickerOptionsDateOfDue"
								:clearable="false"
							></el-date-picker>
							<small
								class="error--text"
								v-if="errors.date_of_due"
								v-text="errors.date_of_due[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="2" lg="4" md="4" sm="4">
						<div
							class="form-group"
							:class="{ 'error--text': errors.exchange_rate_sale }"
						>
							<label>Tipo de cambio</label>
							<el-input v-model="form.exchange_rate_sale"></el-input>
							<small
								class="error--text"
								v-if="errors.exchange_rate_sale"
								v-text="errors.exchange_rate_sale[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" xl="12">
						<v-card color="primary" class="mx-auto pr-2 pl-2" outlined>
							<v-row dense>
								<v-col cols="12" xl="3" lg="3" md="3" sm="4">
									<div class="form-group">
										<label>Cantidad</label>
										<el-input
											v-model="form_item.quantity"
											class="input-text-right"
											ref="quantity"
											autofocus
										></el-input>
									</div>
								</v-col>
								<v-col cols="12" xl="5" lg="5" md="5" sm="8">
									<div class="form-group">
										<label>Producto</label>
										<el-select
											v-model="form_item.item_id"
											filterable
											remote
											reserve-keyword
											placeholder="Buscar..."
											:remote-method="searchItems"
											@change="changeItem"
											:loading="loading_search_items"
											loading-text="Cargando..."
										>
											<el-option
												v-for="item in items"
												:key="item.id"
												:label="item.name"
												:value="item.id"
											></el-option>
										</el-select>
									</div>
								</v-col>
								<v-col cols="12" xl="3" lg="3" md="3" sm="4">
									<div class="form-group">
										<label>Precio Unit.</label>
										<el-input
											v-model="form_item.unit_price_value"
											class="input-text-right"
											@input="changeUnitPriceValue"
										></el-input>
									</div>
								</v-col>
								<v-col cols="12" xl="1" lg="1" md="1" sm="8">
									<el-button
										type="primary"
										icon="el-icon-circle-plus"
										class="btn-block mt-5"
										@click.prevent="clickAddItem"
									></el-button>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>
				<div class="row mt-2">
					<div class="col-md-12">
						<div class="table-responsive">
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th width="3%" class="text-right">N°</th>
											<th width="10%" class="text-right">Cantidad</th>
											<th width="30%">Producto</th>
											<th class="text-right">Precio Unit.</th>
											<th class="text-right">Total</th>
											<th class="text-right">Opciones</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row, index) in form.items" :key="index">
											<td class="text-right align-middle pa-0">
												{{ index + 1 }}
											</td>
											<td class="text-right align-middle pl-0 pr-1">
												<el-input-number
													size="mini"
													v-model="row.quantity"
													controls-position="right"
													:min="0"
													:step="0.1"
													@focus="changeRow(index)"
													@change="changeRow(index)"
												></el-input-number>
											</td>
											<td class="align-middle pl-0 pr-1">
												{{ row.item.name }}
											</td>
											<td class="text-right align-middle pl-0 pr-1">
												<el-input
													size="mini"
													v-model="row.unit_price"
													@input="changeRow(index, false, true)"
													class="input-text-right"
												></el-input>
											</td>
											<td class="text-right align-middle pl-0 pr-1">
												<el-input
													size="mini"
													v-model="row.total"
													class="input-text-right"
													readonly
												></el-input>
											</td>
											<td class="text-right align-middle pa-0">
												<v-menu
													v-model="row.id"
													:close-on-content-click="false"
													:nudge-width="200"
													offset-x
												>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															color="accent"
															class="mr-1"
															dark
															small
															v-bind="attrs"
															v-on="on"
														>
															<v-icon
																>mdi-format-list-bulleted</v-icon
															>
														</v-btn>
													</template>

													<v-card dark color="secondary">
														<v-row class="mr-1 ml-1">
															<v-col cols="12" xl="12" lg="12">
																<label
																	>Impuesto a la Bolsa
																	Plástica</label
																>
																<div class="form-group">
																	<el-switch
																		v-model="
																			row.has_plastic_bag_taxes
																		"
																		active-text="Si"
																		inactive-text="No"
																		@change="changeRow(index)"
																	></el-switch>
																</div>
															</v-col>
														</v-row>
														<v-row class="mr-1 ml-1">
															<v-col cols="12" xl="12" lg="12">
																<div class="form-group">
																	<label>Tipo IGV</label>
																	<el-select
																		v-model="
																			row.affectation_igv_type_id
																		"
																		filterable
																		@change="
																			changeRow(index, true)
																		"
																	>
																		<el-option
																			v-for="option in affectation_igv_types"
																			:key="option.id"
																			:value="option.id"
																			:label="
																				option.description
																			"
																		></el-option>
																	</el-select>
																</div>
															</v-col>
														</v-row>
														<v-row class="mr-1 ml-1">
															<v-col cols="12" xl="12" lg="12">
																<div class="form-group">
																	<label
																		>Descuento del item</label
																	>
																	<el-input
																		v-model="
																			row.discount.percentage
																		"
																		@input="changeRow(index)"
																		class="input-with-select"
																	>
																		<el-select
																			v-model="
																				row.discount
																					.is_amount
																			"
																			slot="prepend"
																			class="custom-select"
																			@input="
																				changeRow(index)
																			"
																		>
																			<el-option
																				:key="1"
																				:value="true"
																				label="Monto"
																			></el-option>
																			<el-option
																				:key="2"
																				:value="false"
																				label="%"
																			></el-option>
																		</el-select>
																	</el-input>
																</div>
															</v-col>
														</v-row>
													</v-card>
												</v-menu>
												<el-button
													size="mini"
													type="danger"
													icon="el-icon-delete"
													@click.prevent="clickRemoveItem(index)"
												></el-button>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</div>
						<v-row dense class="justify-content-end" v-if="form.total > 0">
							<v-col cols="12" xl="6" lg="12" offset-xl="6">
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<!-- <tr v-if="form.total_taxed">
												<td colspan="2" class="text-right align-middle">
													Descuento Global:
												</td>
												<td class="text-right">
													<el-input v-model="total_global_discount" @input="calculateTotal" class="input-with-select input-text-right">
														<el-select v-model="is_amount" slot="prepend" class="custom-select" @input="calculateTotal">
															<el-option :key="1" :value="true" label="Monto"></el-option>
															<el-option :key="2" :value="false" label="%"></el-option>
														</el-select>
													</el-input>
												</td>
											</tr> -->
											<tr v-if="form.total_discount_global > 0">
												<td colspan="2" class="text-right">
													Descuento Global (-):
												</td>
												<td class="text-right">
													{{ currency_type.symbol }}
													{{ form.total_discount_global }}
												</td>
											</tr>
											<tr v-if="form.total_discount_item > 0">
												<td colspan="2" class="text-right">
													Descuento por Item (-):
												</td>
												<td class="text-right">
													{{ currency_type.symbol }}
													{{ form.total_discount_item }}
												</td>
											</tr>
											<tr v-if="form.total_discount > 0">
												<td colspan="2" class="text-right">
													Descuento Total (-):
												</td>
												<td class="text-right">
													{{ currency_type.symbol }}
													{{ form.total_discount }}
												</td>
											</tr>
											<tr v-if="form.total_exportation > 0">
												<td colspan="2" class="text-right">
													Op. Exportación:
												</td>
												<td class="text-right">
													{{ currency_type.symbol }}
													{{ form.total_exportation }}
												</td>
											</tr>
											<tr v-if="form.total_free > 0">
												<td colspan="2" class="text-right">
													Op. Gratuitas:
												</td>
												<td class="text-right">
													{{ currency_type.symbol }} {{ form.total_free }}
												</td>
											</tr>
											<tr v-if="form.total_unaffected > 0">
												<td colspan="2" class="text-right">
													Op. Inafectas:
												</td>
												<td class="text-right">
													{{ currency_type.symbol }}
													{{ form.total_unaffected }}
												</td>
											</tr>
											<tr v-if="form.total_exonerated > 0">
												<td colspan="2" class="text-right">
													Op. Exoneradas:
												</td>
												<td class="text-right">
													{{ currency_type.symbol }}
													{{ form.total_exonerated }}
												</td>
											</tr>
											<tr v-if="form.total_taxed > 0">
												<td colspan="2" class="text-right">Op. Gravada:</td>
												<td class="text-right">
													{{ currency_type.symbol }}
													{{ form.total_taxed }}
												</td>
											</tr>
											<tr v-if="form.total_igv > 0">
												<td colspan="2" class="text-right">IGV:</td>
												<td class="text-right">
													{{ currency_type.symbol }} {{ form.total_igv }}
												</td>
											</tr>
											<tr class="h6" v-if="form.total > 0">
												<td colspan="2" class="text-right">Total:</td>
												<td class="text-right">
													<strong
														>{{ currency_type.symbol }}
														{{ form.total }}</strong
													>
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</div>
				</div>
				<div class="form-actions text-right mt-4">
					<el-button
						class="submit"
						type="primary"
						native-type="submit"
						:loading="loading_submit"
						v-if="form.customer_id && form.total > 0"
					>
						<template v-if="loading_submit">
							Generando...
						</template>
						<template v-else>
							Generar comprobante
						</template>
					</el-button>
				</div>
			</form>
			<section v-if="!opened_cash && loaded">
				<div class="row">
					<div class="col-xl-12">
						<el-alert
							title="Usted no podrá realizar ventas porque no ha aperturado caja."
							type="error"
							center
							effect="dark"
							:closable="false"
						></el-alert>
					</div>
				</div>
			</section>
		</v-card>

		<person-form
			:showDialog.sync="showDialogNewPerson"
			type="customers"
			:external="true"
			:document_type_id="form.document_type_id"
		>
		</person-form>

		<document-options
			:showDialog.sync="showDialogOptions"
			:recordId="documentNewId"
			:statusDocument="statusDocument"
			:isContingency="is_contingency"
			:showClose="true"
		>
		</document-options>
	</div>
</template>
<script>
import PersonForm from '../People/Form';
import DocumentOptions from './Component/Options';
import { functions } from 'Mixins/functions';
import { calculateRowItem } from 'Helpers/functions';
import { confirm } from 'Mixins/confirm';
import filter from 'lodash/filter';
import round from 'lodash/round';
import find from 'lodash/find';
import dayjs from 'dayjs';

export default {
	// props: ['is_contingency'],
	components: { PersonForm, DocumentOptions },
	mixins: [functions, confirm],
	data() {
		return {
			recordItem: null,
			resource: 'documents',
			showDialogNewPerson: false,
			showDialogOptions: false,
			showDialogDebt: false,
			loading_submit: false,
			opened_cash: false,
			loaded: false,
			errors: {},
			form: {},
			form_item: {},
			document_types: [],
			currency_types: [],
			all_customers: [],
			form_payment: {},
			customers: [],
			person: {},
			operation_types: [],
			establishment: {},
			all_affectation_igv_types: [],
			affectation_igv_types: [],
			all_series: [],
			series: [],
			currency_type: {},
			documentNewId: null,
			activePanel: 0,
			loading_search: false,
			loading_search_items: false,
			total_global_discount: 0,
			is_amount: true,
			configuration: {},
			is_contingency: false,
			currentIndex: null,
			items: [],
			row: {},
			searchByBarCode: false,
			input_item: null,
			statusDocument: {},
			exchange_rate_sale: null,
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
			pickerOptionsDateOfDue: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') > time;
				},
			},
			has_error: false,
		};
	},
	async created() {
		await this.initForm();
		await this.initFormItem();
		await this.getDynamicTables();
		await this.getStaticTables();

		this.$eventHub.$on('reloadDataPeople', (customer_id) => {
			this.reloadDataCustomers(customer_id);
		});
	},
	mounted() {
		window.addEventListener(
			'keypress',
			function(e) {
				if (e.which == 43) {
					this.clickAddItem();
				}
			}.bind(this)
		);
	},
	methods: {
		getStaticTables() {
			this.$http.get(`/${this.resource}/static-tables`).then((response) => {
				this.document_types = response.data.document_types_invoice;
				this.currency_types = response.data.currency_types;
				this.operation_types = response.data.operation_types;
				this.all_affectation_igv_types = response.data.affectation_igv_types;

				this.form.currency_type_id =
					this.currency_types.length > 0 ? this.currency_types[0].id : null;
				this.form.operation_type_id =
					this.operation_types.length > 0 ? this.operation_types[0].id : null;

				let operation_type = find(this.operation_types, {
					id: this.form.operation_type_id,
				});
				this.affectation_igv_types = filter(this.all_affectation_igv_types, {
					exportation: operation_type.exportation,
				});

				this.changeDocumentType();
				this.changeCurrencyType();
			});
		},
		getDynamicTables() {
			this.$http.get(`/${this.resource}/dynamic-tables`).then((response) => {
				this.establishment = response.data.establishment;
				this.opened_cash = response.data.opened_cash;
				this.warehouses = response.data.warehouses;
				this.all_series = response.data.series;

				this.form.establishment_id = this.establishment.id;
				this.loaded = true;

				this.changeEstablishment();
				this.changeDateOfIssue();
			});
		},
		addPayment(row) {
			this.form.payments = row;
		},
		searchRemoteCustomers(input) {
			if (input.length > 2) {
				this.loading_search = true;
				let parameters = `
                    input=${input}
                    &document_type_id=${this.form.document_type_id}&
                    operation_type_id=${this.form.operation_type_id}
                `;

				this.$http
					.get(`/${this.resource}/search/customers?${parameters}`)
					.then((response) => {
						this.customers = response.data;
						this.loading_search = false;
					});
			} else {
				this.customers = [];
			}
		},
		initForm() {
			this.errors = {};
			this.form = {
				establishment_id: null,
				document_type_id: null,
				series_id: null,
				number: '#',
				date_of_issue: dayjs().format('YYYY-MM-DD'),
				time_of_issue: dayjs().format('HH:mm:ss'),
				customer_id: null,
				currency_type_id: null,
				purchase_order: null,
				exchange_rate_sale: 0,
				total_prepayment: 0,
				total_charge: 0,
				total_discount_global: 0,
				total_discount_item: 0,
				total_discount: 0,
				total_exportation: 0,
				total_free: 0,
				total_taxed: 0,
				total_unaffected: 0,
				total_exonerated: 0,
				total_igv: 0,
				total_base_isc: 0,
				total_isc: 0,
				total_base_other_taxes: 0,
				total_other_taxes: 0,
				total_plastic_bag_taxes: 0,
				total_taxes: 0,
				total_value: 0,
				total: 0,
				operation_type_id: null,
				date_of_due: dayjs().format('YYYY-MM-DD'),
				items: [],
				charges: [],
				discount: {
					discount_type_id: '02',
					description: 'Descuento Global afecta a la base imponible',
					factor: 0,
					amount: 0,
					base: 0,
				},
				attributes: [],
				guides: [],
				debt: null,
				payments: null,
				additional_information: null,
				plate_number: null,
				actions: {
					format_pdf: 'ticket',
				},
			};

			this.total_global_discount = 0;
			this.is_amount = true;
		},
		resetForm() {
			this.activePanel = 0;
			this.initForm();
			this.form.currency_type_id =
				this.currency_types.length > 0 ? this.currency_types[0].id : null;
			this.form.establishment_id = this.establishment.id;
			// this.form.document_type_id = (this.establishments.length > 0)?this.establishments[0].document_type_id:null
			this.form.operation_type_id =
				this.operation_types.length > 0 ? this.operation_types[0].id : null;

			this.changeEstablishment();
			this.changeDocumentType();
			this.changeDateOfIssue();
			this.changeCurrencyType();
		},
		initFormItem() {
			this.has_error = false;
			this.form_item = {
				item_id: null,
				item: {},
				affectation_igv_type_id: null,
				affectation_igv_type: {},
				has_isc: false,
				system_isc_type_id: null,
				percentage_isc: 0,
				suggested_price: 0,
				quantity: 1,
				unit_price_value: 0,
				static_price: 0,
				unit_price: 0,
				charges: [],
				discount: {
					discount_type_id: '02',
					discount_type: { base: true },
					description: 'Descuento que afecta la base imponible del IGV',
					percentage: 0,
					factor: 0,
					amount: 0,
					base: 0,
					is_amount: true,
				},
				attributes: [],
				has_igv: null,
				has_plastic_bag_taxes: false,
			};
			this.items = [];
		},
		changeEstablishment() {
			this.form.document_type_id = this.establishment.document_type_id;
			this.filterSeries();
		},
		changeDocumentType() {
			this.filterSeries();
			this.cleanCustomer();
		},
		cleanCustomer() {
			this.form.customer_id = null;
			this.customers = [];
		},
		changeDateOfIssue() {
			this.form.date_of_due = this.form.date_of_issue;

			let self = this;
			this.$http
				.post('https://tc.codigopolar.com/api/v1/exchanges/current')
				.then(function(response) {
					let data = response.data.data;

					let sunat = find(data.entities, { name: 'Sunat' });

					if (sunat) {
						self.form.exchange_rate_sale = sunat.exSell;
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		filterSeries() {
			this.form.series_id = null;
			this.series = filter(this.all_series, {
				establishment_id: this.form.establishment_id,
				document_type_id: this.form.document_type_id,
				contingency: this.is_contingency,
			});
			this.form.series_id = this.series.length > 0 ? this.series[0].id : null;
		},
		clickAddItem() {
			if (!this.form_item.item_id) {
				return this.$message.error('Error! selecciona un producto para continuar.');
			}

			if (!Number(this.form_item.quantity)) {
				return this.$message.error('Error! La cantidad ingresada es incorrrecto.');
			}

			if (!Number(this.form_item.unit_price) || this.form_item.unit_price <= 0) {
				return this.$message.error('Error! el precio unitario ingresado es incorrrecto.');
			}

			this.row = calculateRowItem(
				this.form_item,
				this.form.currency_type_id,
				this.form.exchange_rate_sale
			);

			this.form.items.push(JSON.parse(JSON.stringify(this.row)));

			this.initFormItem();
			this.$refs.quantity.$el.getElementsByTagName('input')[0].focus();

			this.calculateTotal();
		},
		clickRemoveItem(index) {
			this.form.items.splice(index, 1);
			this.calculateTotal();
		},
		changeItem() {
			this.form_item.item = find(this.items, { id: this.form_item.item_id });
			this.form_item.affectation_igv_type_id = this.form_item.item.sale_affectation_igv_type_id;
			this.form_item.affectation_igv_type = find(this.affectation_igv_types, {
				id: this.form_item.affectation_igv_type_id,
			});

			this.form_item.unit_price_value = this.form_item.item.sale_unit_price;

			let unit_price = this.form_item.item.has_igv
				? this.form_item.unit_price_value
				: this.form_item.unit_price_value * 1.10;

			this.form_item.unit_price = unit_price;
			this.form_item.static_price = unit_price;
			this.form_item.item.unit_price = unit_price;
		},
		changeUnitPriceValue() {
			let unit_price = this.form_item.item.has_igv
				? this.form_item.unit_price_value
				: this.form_item.unit_price_value * 1.10;

			this.form_item.unit_price = unit_price;
			this.form_item.static_price = unit_price;
			this.form_item.item.unit_price = unit_price;
		},
		searchItems(input) {
			if (input.length > 2) {
				this.loading_search_items = true;
				let parameters = `input=${input}`;
				this.$http.get(`/${this.resource}/search/items?${parameters}`).then((response) => {
					this.items = response.data;
					this.loading_search_items = false;
				});
			} else {
				this.items = [];
			}
		},
		async searchItemByBarCode() {
			if (this.input_item) {
				let parameters = `input=${this.input_item}`;
				await this.$http
					.get(`/${this.resource}/search/item/bar-code?${parameters}`)
					.then((response) => {
						if (response.data.success) {
							this.form_item.item_id = response.data.data.id;
							this.items.push(response.data.data);
							this.autoAddItem(response.data);
						} else {
							this.$message.error(response.data.message);
						}

						this.input_item = null;
					});
			}
		},
		async autoAddItem(data) {
			await this.changeItem();
			await this.sleep(250);
			if (!this.has_error) {
				this.clickAddItem(data.data);
				this.$message.success(data.message);
			} else {
				this.has_error = false;
			}
		},
		sleep(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms));
		},
		getCurrentIndex(index) {
			this.currentIndex = index;
		},
		changeCurrencyType() {
			this.currency_type = find(this.currency_types, { id: this.form.currency_type_id });
			let items = [];
			this.form.items.forEach((row) => {
				items.push(
					calculateRowItem(row, this.form.currency_type_id, this.form.exchange_rate_sale)
				);
			});
			this.form.items = items;
			this.calculateTotal();
		},
		changeRow(index, change_igv_type = false, input = false) {
			this.form.items[index].affectation_igv_type = find(this.affectation_igv_types, {
				id: this.form.items[index].affectation_igv_type_id,
			});

			if (change_igv_type) {
				this.form.items[index].unit_price_value = this.form.items[index].static_price;
			} else {
				this.form.items[index].unit_price_value = this.form.items[index].unit_price;
			}

			let unit_price = this.form.items[index].item.has_igv
				? this.form.items[index].unit_price_value
				: this.form.items[index].unit_price_value * 1.10;

			this.form.items[index].item.unit_price = unit_price;

			this.calculateRowItem(index, change_igv_type, input);
		},
		calculateRowItem(index, change_igv_type, input) {
			let currency_type_id_new = this.form.currency_type_id;
			let exchange_rate_sale = this.form.exchange_rate_sale;
			let row = this.form.items[index];
			let currency_type_id_old = row.item.currency_type_id;
			let unit_price = parseFloat(row.item.unit_price);

			if (currency_type_id_old === 'PEN' && currency_type_id_old !== currency_type_id_new) {
				unit_price = unit_price / exchange_rate_sale;
			}

			if (currency_type_id_new === 'PEN' && currency_type_id_old !== currency_type_id_new) {
				unit_price = unit_price * exchange_rate_sale;
			}

			let percentage_igv = 10;
			row.static_price = input ? row.unit_price : row.static_price;
			row.unit_price = change_igv_type ? row.static_price : row.unit_price;
			let unit_value = row.unit_price;

			if (row.item_id !== null) {
				if (row.affectation_igv_type.free) {
					unit_value = parseFloat(row.unit_price / (1 + percentage_igv / 100) / 1.10);
				}
			}

			if (row.affectation_igv_type_id === '10') {
				unit_value = row.unit_price / (1 + percentage_igv / 100);
			}

			console.log(unit_price);

			row.unit_value = unit_value;

			let total_value_partial = unit_value * row.quantity;

			/* Discounts */
			let discount_base = 0;
			let discount_no_base = 0;
			let discount = row.discount;
			if (discount.percentage > 0) {
				if (discount.is_amount) {
					discount.base = total_value_partial;
					discount.amount = discount.percentage / 1.10;

					let percentage =
						100 * (parseFloat(discount.amount) / parseFloat(discount.base));
					discount.factor = percentage / 100;
					if (discount.discount_type.base) {
						discount_base += discount.amount;
					} else {
						discount_no_base += discount.amount;
					}
				} else {
					let percentage = parseFloat(discount.percentage);
					discount.factor = percentage / 100;
					discount.base = total_value_partial;
					discount.amount = discount.base * discount.factor;
					if (discount.discount_type.base) {
						discount_base += discount.amount;
					} else {
						discount_no_base += discount.amount;
					}
				}
			}

			/* Charges */
			let charge_base = 0;
			let charge_no_base = 0;

			let total_isc = 0;
			let total_other_taxes = 0;

			let total_discount = discount_base + discount_no_base;
			let total_charge = charge_base + charge_no_base;
			let total_value = total_value_partial - total_discount + total_charge;
			let total_base_igv = total_value_partial - discount_base + total_isc;

			let total_igv = 0;

			if (row.affectation_igv_type_id === '10') {
				total_igv = (total_base_igv * percentage_igv) / 100;
			}
			if (row.affectation_igv_type_id === '20') {
				//Exonerated
				total_igv = 0;
			}
			if (row.affectation_igv_type_id === '30') {
				//Unaffected
				total_igv = 0;
			}

			let total_taxes = total_igv + total_isc + total_other_taxes;
			let total = total_value + total_taxes;

			row.total_charge = total_charge;
			row.total_discount = total_discount;
			row.total_charge = total_charge;
			row.total_value = total_value;
			row.total_base_igv = total_base_igv;
			row.total_igv = total_igv;
			row.total_taxes = total_taxes;
			row.total = round(total, 2);

			if (row.item_id !== null) {
				if (row.affectation_igv_type.free) {
					row.price_type_id = '02';
					row.unit_price = parseFloat(total_value_partial * 1.10);
					row.total_value = parseFloat(
						total_value_partial - total_discount + total_charge
					);
					row.total_igv = parseFloat(row.total_value * 0.10);
					row.total = parseFloat(row.total_value + row.total_igv);
				} else {
					row.price_type_id = '01';
				}
			}

			//impuesto bolsa
			if (row.has_plastic_bag_taxes) {
				row.total_plastic_bag_taxes = round(
					row.quantity * row.item.amount_plastic_bag_taxes,
					1
				);
			}

			this.calculateTotal();
		},
		calculateTotal() {
			let total_discount_item = 0;
			// let total_discount = 0
			let total_charge = 0;
			let total_exportation = 0;
			let total_taxed = 0;
			let total_exonerated = 0;
			let total_unaffected = 0;
			let total_free = 0;
			let total_igv = 0;
			let total_value = 0;
			let total = 0;
			this.form.items.forEach((row) => {
				total_discount_item += parseFloat(row.total_discount);
				total_charge += parseFloat(row.total_charge);

				if (row.affectation_igv_type_id === '10') {
					total_taxed += parseFloat(row.total_value);
				}
				if (row.affectation_igv_type_id === '20') {
					total_exonerated += parseFloat(row.total_value);
				}
				if (row.affectation_igv_type_id === '30') {
					total_unaffected += parseFloat(row.total_value);
				}
				if (row.affectation_igv_type_id === '40') {
					total_exportation += parseFloat(row.total_value);
				}
				if (['10', '20', '30', '40'].indexOf(row.affectation_igv_type_id) < 0) {
					total_free += parseFloat(row.total_value);
				}

				total_value += parseFloat(row.total_value);
				total_igv += parseFloat(row.total_igv);
				total += parseFloat(row.total);
			});

			let amount = 0;
			let factor = 0;
			if (this.total_global_discount > 0) {
				if (this.is_amount) {
					amount = parseFloat(this.total_global_discount / 1.10);
					factor = parseFloat(amount / total_taxed);
				} else {
					amount = parseFloat(total_taxed * (this.total_global_discount / 100));
					factor = parseFloat(this.total_global_discount / 100);
				}

				this.form.discount.base = round(total_taxed, 2);
				this.form.discount.amount = round(amount, 2);
				this.form.discount.factor = round(factor, 5);

				total_value = parseFloat(total_taxed - amount);
				total_igv = parseFloat(total_value * 0.10);
				total_taxed = parseFloat(total_value);
				total = parseFloat(total_value + total_igv);
			}

			this.form.total_discount_item = round(total_discount_item, 2);
			this.form.total_discount_global = round(amount, 2);
			this.form.total_discount = round(total_discount_item + amount, 2);
			this.form.total_exportation = round(total_exportation, 2);
			this.form.total_charge = round(total_charge, 2);
			this.form.total_taxed = round(total_taxed, 2);
			this.form.total_exonerated = round(total_exonerated, 2);
			this.form.total_unaffected = round(total_unaffected, 2);
			this.form.total_free = round(total_free, 2);
			this.form.total_igv = round(total_igv, 2);
			this.form.total_value = round(total_value, 2);
			this.form.total_taxes = round(total_igv, 2);
			this.form.total = round(total, 2);
		},
		changeTypeDiscount() {
			this.calculateTotal();
		},
		discountGlobal() {
			let base = this.form.total_taxed;
			let amount = this.is_amount
				? parseFloat(this.total_global_discount / 1.10)
				: base * (parseFloat(this.total_global_discount) / 100);
			let factor = this.is_amount
				? amount / base
				: parseFloat(this.total_global_discount) / 100;

			if (this.total_global_discount > 0 && this.form.discounts.length == 0) {
				this.form.discounts.push({
					discount_type_id: '02',
					description: 'Descuento Global afecta a la base imponible',
					factor: 0,
					amount: 0,
					base: 0,
				});
			}

			if (this.form.discounts.length) {
				let total_discount_item = 0;
				this.form.items.forEach((row) => {
					total_discount_item += parseFloat(row.total_discount);
				});

				this.form.total_discount_global = round(amount, 2);
				this.form.total_discount_item = round(total_discount_item, 2);
				this.form.total_discount = round(total_discount_item + amount, 2);
				this.form.total_value = round(base - amount, 2);
				this.form.total_igv = round(this.form.total_value * 0.18, 2);
				this.form.total_taxes = round(this.form.total_igv, 2);
				this.form.total_taxed = round(this.form.total_value, 2);
				this.form.total = round(this.form.total_value + this.form.total_taxes, 2);

				this.form.discounts[0].base = base;
				this.form.discounts[0].amount = amount;
				this.form.discounts[0].factor = factor;
			}
		},
		async submit() {
			if (!this.form.series_id) {
				return this.$message.error(
					'El comprobante seleccionado de no tiene serie, asignar para continuar'
				);
			}

			this.confirm().then(() => {
				this.loading_submit = true;
				this.$http
					.post(`/${this.resource}`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.resetForm();
							this.documentNewId = response.data.data.id;
							this.statusDocument = response.data.data.response;
							this.showDialogOptions = true;
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errors = error.response.data.errors;
						} else {
							this.$message.error(error.response.data.message);
						}
					})
					.then(() => {
						this.loading_submit = false;
					});
			});
		},
		reloadDataCustomers(customer_id) {
			this.$http.get(`/${this.resource}/search/customer/${customer_id}`).then((response) => {
				this.customers = response.data;
				this.form.customer_id = customer_id;
			});
		},
	},
};
</script>
